import React from 'react'
import { PageProps } from 'gatsby'
import {
  Feedbacks,
  Footer,
  Head,
  Insurances,
  LinkInsurances,
  Newsletter,
  Numbers,
  Questions,
  SliderAd,
  SliderIntro,
} from '~/components'
import { LazyImage } from 'react-lazy-images'
import SafePage from './_seguro'
import { useApi } from '~/api'
import * as st from '~/assets/styl/Home.module.styl'

const Home = ({ location, pageContext }: PageProps) => {
  const context = useApi(pageContext, 'page-home')

  const {
    showcases,
    safes,
    banners,
    benefits,
    testimonials,
    faqs,
    insurers,
    numbers,
  } = context

  return (
    <>
      <Head />
      <main className={st.core}>
        <SliderIntro data={showcases} />
        <Insurances
          location={location}
          hasClipPath
          text="Conheça os seguros que a Dimas Seguros oferece"
          data={safes}
        />
        <SliderAd data={banners} />
        <section className="container">
          <h2>A conexão entre você e sua tranquilidade</h2>
          <p>Saiba o porquê de escolher a Dimas Seguros</p>
          <div className={st.reasons}>
            {benefits.map(({ image, title, description }, index) => (
              <div key={index}>
                <div>
                  <LazyImage
                    src={image}
                    alt=""
                    placeholder={({ imageProps, ref }) => (
                      <img ref={ref} src={image} alt={imageProps.alt} />
                    )}
                    actual={({ imageProps }) => <img {...imageProps} />}
                  />
                  <section>
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </section>
                </div>
              </div>
            ))}
          </div>
        </section>
        <LinkInsurances location={'/'} />
        <Numbers data={numbers} location="/" />
        <Feedbacks data={testimonials} />
        <Questions data={faqs} />
        <section className={st.partners}>
          <h2>Seguradoras</h2>
          <p>Conheça as seguradoras parceiras Dimas Seguros</p>
          <ul>
            {insurers.map(({ image, name }, index) => (
              <li key={index}>
                <img src={image} alt={name} />
              </li>
            ))}
          </ul>
        </section>
        <Newsletter />
      </main>
      <Footer hasNewsletter />
    </>
  )
}

const HomeRouter = ({ '*': slug, ...props }: PageProps & { '*': string }) =>
  slug ? <SafePage {...props} slug={slug} /> : <Home {...props} />

export default HomeRouter
